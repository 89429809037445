@import 'react-owl-carousel2/lib/styles.css';

.root {
    flex-grow: 1;
}

.title {
    flex-grow: 1;
}

.button {
    margin: 0 10px;
}

.cardGrid {
    /* padding: 64px 0; */
}

.card {
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* .cardContent {
    flex-grow: 1;
} */

.feedbackPaper {
    padding: 20px;
}

.questionPaper {
    padding: 20px;
}

.footer {
    padding: 48px 0;
    text-align: center;
}

/*  */

.header-appbar.MuiAppBar-root {
    position: sticky;
    padding: 32px 7.5%;
    top: 0;
    background: #fff !important;
    border-bottom: 1px solid #dedede;
    z-index: 10;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.header-appbar .social-connect {
    display: flex;
    gap: 12px;
}

.header-appbar .social-connect .social-connect-icon {
    width: 40px;
    height: 40px;
}

.header-appbar.MuiAppBar-root p {
    margin-left: 0px !important;
}
.toolbar {
    display: flex;
    justify-content: space-between;
}

.menuButton {
    /* margin-right: 16px; / */
}

.title {
    flex-grow: 1;
}

.navLinks {
    display: flex;
    align-items: center;
    gap: 50px;
}

.nav-menus {
    text-transform: none !important;
    color: #0c0c0c !important;
    font-family: 'Sk-Modernist' !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19.2px !important;
}

.nav-menus:hover {
    text-transform: none !important;
    color: #61a3f2 !important;
    font-family: 'Sk-Modernist' !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19.2px !important;
}

.get-started-btn {
    background-color: #61a3f2 !important;
    color: #fff !important;
    text-transform: none !important;
    font-family: 'Sk-Modernist' !important;
    padding: 15px 32px !important;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
}

.link {
    margin: 0 8px;
}

.drawerPaper {
    width: 240px;
}
/*  */

/* Hero Content Starts Here */
.hero-content {
    padding: 50px 0;
    text-align: center;
}

.hero-content .company-logo {
    min-width: 120px !important;
    max-width: 120px !important;
    height: 120px !important;
    margin-right: 21px !important;
    border-radius: 50%;
    border: 2px solid #864ffd;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.hero-content .company-logo .company-logo-img {
    width: 117px;
    height: 117px;
    border-radius: 50%;
}

.hero-content .title {
    font-family: 'Sk-Modernist';
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    text-align: center;
    /* background-image: url('assets/landing-hero-bg.svg'); */
}

.hero-content .hero-bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.hero-content .para {
    font-family: 'Gotham';
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #757575;
}

.hero-buttons {
    /* display: flex; */
    justify-content: center;
    margin-top: 20px;
    /* gap: 20px; */
}

.contact-us-btn {
    color: #61a3f2 !important;
    background-color: #fff !important;
    border: 2px solid #61a3f2 !important;
    text-transform: none !important;
    font-family: 'Sk-Modernist' !important;
    padding: 15px 32px !important;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
}

.hero-banner {
    padding-bottom: 100px;
}

.header-title {
    font-family: 'Sk-Modernist' !important;
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 36px !important;
    color: #393939 !important;
}

.text-para {
    font-family: 'Gotham' !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    color: #686868 !important;
}

/* Hero Content Ends Here */

/* About Company Section Starts Here */

.about-company-sec {
    padding-bottom: 48px;
}

.check-para-main {
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    padding: 16px 22px 16px 22px;
}

.check-para-sec {
    display: flex;
    align-items: center;
    /* margin-bottom: 18px; */
}

.check-para-sec .check-para {
    font-family: 'Sk-Modernist' !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 34px;
    color: #393939;
}

.landing-main-divider {
    width: 100%;
    border: 1px solid #e3e3e3;
    opacity: 100%;
}

/* About Company Section Ends Here */

/* Popular Product Section Starts Here */

.popular-products-sec {
    padding: 50px 0;
}

.popular-products-sec .popular-products-card {
    position: relative;
    box-shadow: none;
}

.popular-products-sec .popular-products-card .cardContent {
    padding: 15px 0px 0px 0px;
    width: 100%;
}

.popular-products-sec .popular-products-card .cardContent .product-title {
    font-family: 'Sk-Modernist';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #393939;
    margin-bottom: 6px;
}

.popular-products-sec .popular-products-card .cardContent .product-subtitle {
    font-family: 'Sk-Modernist';
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #864ffd;
}

.popular-products-sec .popular-products-card .cardMedia {
    height: 280px;
    border-radius: 10px;
    position: relative;
}


.view-more-button {
    background: #864FFD !important;
    padding: 12px 40px !important;
    font-family: 'Sk-Modernist' !important;
    font-size: 15px !important;
    font-weight: 700;
    line-height: 18px;
}

.btn-enquire-now {
    color: #864ffd !important;
    font-size: 15px !important;
    text-transform: none !important;
    text-decoration: underline !important;
    cursor: pointer;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    padding: 0px !important;
}

/* .popular-products-sec .popular-products-card .cardMedia::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent 50%);
    pointer-events: none;
    border-radius: 10px;
} */

/* Hover effect */
/* .popular-products-sec .popular-products-card:hover .cardMedia {
    background-color: rgba(97, 163, 242, 0.8); 
    cursor: pointer;
    border-radius: 10px;
}

.popular-products-sec .popular-products-card:hover .cardMedia:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: rgba(97, 163, 242, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
} 

.popular-products-sec .popular-products-card:hover .product-subtitle {
    color: #fff; 
} */

/* Popular Product Section Ends Here */

/* Social Media Section Starts Here */
.social-media-sec {
    padding: 40px 0px;
    border-bottom: 1px solid #e3e3e3;
}

.social-icons {
    display: flex;
    gap: 12px;
}

.social-icons .social-icon-button {
    padding: 0px;
    box-shadow: 0px 6px 40px 0px #0000001a;
}

.social-icons .social-hover-icon {
    display: none;
}

.social-icons .social-icon-button:hover .social-icon {
    display: none;
}

.social-icons .social-icon-button:hover .social-hover-icon {
    display: inline;
}
/* Social Media Section Ends Here */

/* Testimonails Section Starts Here */

.landing-testimonials-sec {
    padding-bottom: 50px;
}

.landing-testimonials-sec .testimonials-header {
    font-family: 'Sk-Modernist';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #fff;
    margin-bottom: 6px;
    text-transform: uppercase;
}

.landing-testimonials-sec .testimonials-title {
    font-family: 'Sk-Modernist';
    font-size: 44px;
    font-weight: 700;
    line-height: 52.8px;
    text-align: center;
    color: #fff;
    margin-bottom: 52px;
}

.feedback-paper {
    background: #f3eeff !important;
    padding: 33px 30px !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    margin: 0px 15px 40px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.feedback-paper .testimonial-feedback {
    font-family: 'Gotham' !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    color: #404040 !important;
    margin-bottom: 20px !important;
    line-break: auto;
    word-break: break-word;
}

.feedback-paper .testimonial-name {
    font-family: 'Sk-Modernist' !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    text-align: left;
    color: #0c0c0c;
    margin-bottom: 10px !important;
    line-break: auto;
    word-break: break-word;
}

.feedback-paper .testimonial-role {
    font-family: 'Gotham' !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 14.36px !important;
    text-align: left !important;
    color: #404040 !important;
    line-break: auto;
    word-break: break-word;
}

.landing-testimonials-sec .owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 5px 7px;
    background: #c9c9f6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 50%;
}

.landing-testimonials-sec .owl-theme .owl-dots .owl-dot.active span,
.landing-testimonials-sec .owl-theme .owl-dots .owl-dot:hover span {
    background: #864ffd;
    width: 20px;
    height: 8px;
    border-radius: 4px;
}

/* Testimonails Section Ends Here */

/* Contact Us Section Starts Here */

.contact-us-sec {
    padding: 50px 0px;
}

.contact-us-sec .get-in-touch-bg {
    width: 100%;
    background: #864ffd;
    border-radius: 16px;
    padding: 70px 60px;
}

.contact-us-sec .get-in-touch-bg .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    border-width: 2px !important;
}

.contact-us-sec .get-in-touch-bg .MuiInputLabel-root.Mui-focused {
    color: #fff;
    font-size: 21px;
    top: -14px;
    left: -12px;
    display: block !important;
}

.contact-us-sec .get-in-touch-bg .MuiInputLabel-root.MuiInputLabel-shrink {
    color: #fff;
    font-size: 21px;
    top: -14px;
    left: -12px;
}

.contact-us-sec .get-in-touch-bg .MuiInputBase-input,
.contact-us-sec .get-in-touch-bg .MuiInputBase-input::placeholder {
    font-family: 'Gotham' !important;
    color: #7c7c7c !important;
    font-size: 15px !important;
    opacity: 100% !important;
    background-color: #fff;
    line-height: 14.36px;
    border-radius: 4px;
}

.contact-us-sec .get-in-touch-bg .MuiInputBase-multiline {
    padding: 0px !important;
}

.contact-us-sec .get-in-touch-bg .get-intouch-btn {
    padding: 16px;
    background-color: #000;
    color: #fff;
    font-family: 'Sk-Modernist' !important;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    text-transform: none !important;
    padding: 16px 47px;
}

.contact-us-sec .get-in-touch-bg .touch-title {
    font-family: 'Sk-Modernist' !important;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    color: #fff !important;
    margin-bottom: 10px;
}

.contact-us-sec .get-in-touch-bg .touch-para {
    font-family: 'Gotham' !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    color: #fff !important;
    text-align: left;
    margin-bottom: 30px;
}

.contact-us-sec .contact-header {
    font-family: 'Sk-Modernist' !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 19.2px !important;
    letter-spacing: 0.02em !important;
    text-transform: uppercase !important;
    color: #61a3f2 !important;
    margin-bottom: 7px;
}

.contact-us-sec .contact-title {
    font-family: 'Sk-Modernist' !important;
    font-size: 44px;
    font-weight: 700;
    line-height: 52.8px;
    text-align: left;
    margin-bottom: 28px;
    color: #0c0c0c;
}

.contact-us-sec .contact-para {
    font-family: 'Gotham' !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    margin-bottom: 27px;
    color: #404040;
}

.contact-us-sec .contact-title2 {
    font-family: 'Gotham' !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    margin-bottom: 20px;
    color: #404040;
}

.contact-us-sec .contact-number-main {
    display: flex;
    gap: 8px;
    align-items: center;
}

.contact-us-sec .contact-number-main .contact-number {
    font-family: 'Gotham' !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #0c0c0c;
}
/* Contact Us Section Ends Here */

/* Policy Section Starts Here */
.policy-sec {
    padding-bottom: 50px;
}

.policy-sec .policy-list {
    list-style: disc;
    padding-left: 20px;
    padding-top: 0px;
}

.policy-sec .policy-list .list-item {
    display: list-item !important;
    padding-top: 0px !important;
    color: #686868;
}

.policy-sec .policy-list .list-item-text {
    font-family: 'Gotham' !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 24px;
    text-align: left;
    color: #686868 !important;
}
/* Policy Section Ends Here */

/* Modal Section Starts Here */

.landing-modal-content {
    padding: 30px !important;
}

.landing-modal-sec .modal-header {
    font-family: 'Sk-Modernist' !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: left;
    margin-bottom: 17px;
    color: #000;
}

.landing-modal-sec .modal-title {
    font-family: 'Gotham' !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 13.4px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 17px;
    color: #61a3f2;
}

.landing-modal-sec .modal-price {
    font-family: 'Sk-Modernist' !important;
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    color: #61a3f2 !important;
    margin-bottom: 20px;
}

.landing-modal-sec span.modal-hour {
    font-family: 'Sk-Modernist' !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #393939 !important;
}

.landing-modal-sec .modal-para {
    font-family: 'Gotham' !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
    color: #404040;
}

.landing-modal-sec .check-para-main {
    margin-bottom: 29px;
}

.landing-modal-sec .check-para-sec {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.landing-modal-sec .check-para-sec .check-para {
    font-family: 'Sk-Modernist' !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: #404040;
}

/* Modal Section Ends Here */

/* Footer Section Starts Here */
.landing-footer {
    width: 100%;
}

.landing-footer-top {
    background-color: #fbf7ff;
    padding: 55px 0px 55px 0px;
}

.landing-footer .footer-title.brand-title {
    font-family: 'Sk-Modernist' !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    text-align: left;
    color: #0c0c0c !important;
    margin-bottom: 29px;
}

.landing-footer .footer-title {
    font-family: 'Sk-Modernist' !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 24px;
}

.landing-footer .footer-link {
    color: #404040;
    text-decoration: none;
    font-family: 'Gotham' !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
}

.landing-footer .footer-link:hover,
.landing-footer .footer-link:active {
    text-decoration: none !important;
    color: #61a3f2 !important;
}

.landing-footer .footer-text {
    font-family: 'Gotham' !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #404040;
}

.landing-footer .footer-flex {
    display: flex;
    gap: 8px;
    flex-direction: column;
}



.landing-footer-bottom {
    background-color: #fff;
    padding: 20px 0px;
}

.landing-footer-bottom .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landing-footer-bottom .footer-bottom .footer-bottom-inner {
    display: flex;
    gap: 64px;
}

.landing-footer-bottom .footer-bottom .footer-bottom-inner .text-copyright {
    font-family: 'Gotham' !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 14.36px;
    text-align: left;
    color: #404040 !important;
}

.landing-footer-bottom .footer-bottom .footer-bottom-inner .footer-link {
    font-family: 'Gotham' !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 14.36px;
    text-align: left;
    color: #404040 !important;
}

.landing-footer .footer-link:hover {
    text-decoration: underline;
}

.owl-stage {
    display: flex;
}

.testimonial-single-carousel .owl-nav{
    display: none !important;
}
/* Footer Section Ends Here */

@media (max-width: 600px) {

    .btn-enquire-now {
        color: #864ffd !important;
        font-size: 14px !important;
        text-transform: none !important;
        text-decoration: underline !important;
        cursor: pointer;
        font-weight: 700 !important;
        font-family: 'Sk-Modernist' !important;
        padding: 0px !important;
    }

    .header-appbar.MuiAppBar-root {
        position: sticky;
        padding: 24px 18px;
        top: 0;
        background: #fff !important;
        border-bottom: 1px solid #dedede;
        z-index: 10;
        width: 100% !important;
    }

    .header-appbar.MuiAppBar-root .landing-brand-logo {
        height: 30px;
    }

    .header-appbar .social-connect .social-connect-icon {
        width: 36px;
        height: 36px;
    }

    .heroContent {
        padding: 32px 0 24px;
    }

    .hero-content .company-logo {
        min-width: 82px !important;
        max-width: 82px !important;
        height: 82px !important;
        margin-right: 16px !important;
        border-radius: 50%;
        border: 2px solid #864ffd;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
    }

    .hero-content .company-logo .company-logo-img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    .landing-mobile-menus .MuiTypography-root {
        font-size: 14px;
    }

    .popular-products-sec .popular-products-card .cardContent .product-title {
        font-family: 'Sk-Modernist';
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #393939;
        margin-bottom: 6px;
    }

    .popular-products-sec .popular-products-card .cardContent .product-subtitle {
        font-family: 'Sk-Modernist';
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: #864ffd;
    }

    .button {
        margin: 5px;
    }

    .feedbackPaper {
        padding: 10px;
    }

    .questionPaper {
        padding: 10px;
    }

    .footer {
        padding: 24px 0;
    }
    .menuButton {
        display: block;
    }

    .navLinks {
        display: none;
    }

    .link {
        margin: 8px 0;
    }

    .hero-content {
        padding: 30px 0 40px;
        text-align: center;
    }

    .hero-content .title {
        font-family: 'Sk-Modernist' !important;
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        text-align: start;
    }

    .hero-content .para {
        font-family: 'Gotham';
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: start;
        color: #757575;
    }

    .hero-buttons {
        display: flex;
        justify-content: start;
        margin-top: 20px;
    }

    .hero-content .title br {
        display: none;
    }

    .hero-content .hero-bg {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        width: 171px;
        height: 70px;
    }

    .hero-content .hero-bg .hero-bg-img {
        width: 171px;
        height: 70px;
    }

    .hero-banner {
        padding-bottom: 36px;
    }

    .about-company-sec {
        gap: 25px;
        padding-bottom: 33px;
    }

    .header-title {
        font-family: 'Sk-Modernist' !important;
        font-size: 20px !important;
        font-weight: 700 !important;
        line-height: 24px !important;
        color: #393939 !important;
    }

    .text-para {
        font-family: 'Gotham' !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        color: #686868 !important;
    }

    .about-company-sec .check-para-sec {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .about-company-sec .check-para-sec .check-para {
        font-family: 'Sk-Modernist' !important;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 32px !important;
        text-align: left;
        color: #404040;
        font-family: Sk-Modernist;
    }

    .popular-products-sec {
        padding: 35px 0;
    }

    .popular-products-sec .product-header {
        font-family: 'Sk-Modernist';
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: start;
        color: #61a3f2;
        margin-bottom: 6px;
        text-transform: uppercase;
    }

    .popular-products-sec .product-subheader {
        font-family: 'Sk-Modernist' !important;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: start;
        color: #0c0c0c;
        margin-bottom: 25px;
    }

    .popular-products-slider {
        display: flex !important;
        flex-wrap: nowrap !important;
        overflow-x: auto !important;
    }

    .popular-products-sec .popular-products-card .cardMedia {
        height: 170px !important;
        width: 160px !important;
        border-radius: 8px;
    }

    .landing-testimonials-sec {
        padding: 40px 0;
    }

    .landing-testimonials-sec .testimonials-header {
        font-family: 'Sk-Modernist' !important;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: start;
        color: #fff;
        margin-bottom: 7px;
        text-transform: uppercase;
    }

    .landing-testimonials-sec .testimonials-title {
        font-family: 'Sk-Modernist' !important;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: start;
        color: #fff;
        margin-bottom: 25px;
    }

    .feedback-paper {
        background: #f3eeff !important;
        padding: 27px 25px !important;
        border-radius: 10px !important;
        box-shadow: none !important;
        margin: 0px 0px 15px 0px;
    }

    .feedback-paper .testimonial-name {
        font-family: 'Sk-Modernist' !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 19px !important;
        text-align: left;
        color: #0c0c0c;
        margin-bottom: 10px !important;
        line-break: auto;
        word-break: break-word;
    }

    .feedback-paper .testimonial-role {
        font-family: 'Gotham' !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 13.4px !important;
        text-align: left !important;
        color: #404040 !important;
        line-break: auto;
        word-break: break-word;
    }

    .feedback-paper .testimonial-feedback {
        font-family: 'Gotham' !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 26px !important;
        text-align: left;
        color: #404040 !important;
        margin-bottom: 20px !important;
        line-break: auto;
        word-break: break-word;
    }

    .feedback-paper .quote-icon {
        width: 46px;
        height: 34px;
    }

    .contact-us-sec {
        padding: 24px 0px !important;
    }

    .contact-us-sec .contact-header {
        font-family: 'Sk-Modernist' !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 18px !important;
        letter-spacing: 0.02em !important;
        text-transform: uppercase !important;
        color: #61a3f2 !important;
        margin-bottom: 7px;
    }

    .contact-us-sec .contact-title {
        font-family: 'Sk-Modernist' !important;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: left;
        margin-bottom: 14px;
        color: #0c0c0c;
    }

    .contact-us-sec .contact-para {
        font-family: 'Gotham' !important;
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        margin-bottom: 20px;
        color: #404040;
    }

    .contact-us-sec .contact-title2 {
        font-family: 'Gotham' !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        margin-bottom: 10px;
        color: #404040;
    }

    .contact-us-sec .get-in-touch-bg {
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 16px;
        padding: 23px 26px 30px 26px;
    }

    .contact-us-sec .get-in-touch-bg .touch-title {
        font-family: 'Sk-Modernist' !important;
        font-size: 22px;
        font-weight: 700;
        line-height: 26.4px;
        text-align: left;
        color: #fff !important;
        margin-bottom: 7px;
    }

    .contact-us-sec .get-in-touch-bg .touch-para {
        font-family: 'Gotham' !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        color: #fff !important;
        text-align: left;
        margin-bottom: 19px;
    }

    .landing-testimonials-sec {
        padding-bottom: 40px;
    }

    .landing-mobile-menus .close-button {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .landing-mobile-menus .close-button-icon.MuiIconButton-root {
        padding: 1rem;
    }

    .landing-footer .footer-grid {
        grid-template-columns: 1fr;
    }

    .landing-footer .footer-grid-item-md-4,
    .landing-footer .footer-grid-item-remaining {
        grid-column: span 1;
    }

    .landing-footer-bottom .footer-bottom-inner {
        display: none !important;
    }

    .landing-footer .footer-mobile-grid-hide {
        display: none;
    }

    .landing-footer .footer-mobile-icons {
        display: flex;
        gap: 10px;
    }

    .footer-mobile-icons .footer-icon-button {
        /* box-shadow:
            0px 3px 3px -2px rgba(0, 0, 0, 0.2),
            0px 3px 4px 0px rgba(0, 0, 0, 0.14),
            0px 1px 8px 0px rgba(0, 0, 0, 0.12); */
        box-shadow: 0px 6px 40px 0px #0000001a;
    }

    .landing-footer .footer-bottom {
        text-align: center;
        display: flex;
        gap: 20px;
        justify-content: center !important;
        align-items: center;
        flex-direction: column;
    }

    .landing-modal-dialog .close-icon-mobile {
        position: absolute;
        top: 10px !important;
        right: 10px !important;
    }
}

@media (max-width: 960px) {
    .heroContent {
        padding: 48px 0 36px;
    }

    .cardGrid {
        /* padding: 48px 0; */
    }

    .button {
        margin: 5px;
    }

    .feedbackPaper {
        padding: 15px;
    }

    .questionPaper {
        padding: 15px;
    }

    .footer {
        padding: 36px 0;
    }

    .popular-products-slider {
        display: flex !important;
        flex-wrap: nowrap !important;
        overflow-x: auto !important;
    }

    .popular-products-sec .popular-products-card .cardMedia {
        height: 240px;
        width: 230px;
        border-radius: 8px;
    }
}

@media (min-width: 600px) {
    .menuButton {
        display: none !important;
    }

    .MuiContainer-root {
        width: 95% !important;
    }

    /* .header-appbar.custom-container {
        width: 95% !important;
        padding-left: 24px;
        padding-right: 24px;
    } */
}

@media (min-width: 991px) {
    .MuiContainer-root.custom-container {
        width: 85% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    /* .header-appbar.custom-container {
        width: 85% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    } */
}

@media (max-width: 991px) {
    .is-desktop {
        display: none !important;
    }

    /* .about-company-sec .about-company-desktop {
        display: none;
    }

    .about-company-sec .about-company-mobile {
        display: block;
    } */

    .about-company-sec .about-company-desktop .tablet-height {
        height: 600px !important;
    }

    .contact-us-sec {
        padding: 50px 0px;
    }

    .about-company-sec {
        gap: 30px;
        /* padding-bottom: 33px; */
    }

    .landing-footer .footer-flex {
        display: flex;
        gap: 8px;
        flex-direction: column;
    }
}

/* Tablet view */
@media (min-width: 600px) and (max-width: 959px) {
    .landing-footer .footer-grid {
        grid-template-columns: repeat(12, 1fr);
    }

    .landing-footer .footer-grid-item-md-4 {
        grid-column: span 5;
    }

    .landing-footer .footer-grid-item-remaining {
        grid-column: span 3;
    }

    .landing-footer .footer-bottom {
        text-align: center;
    }
}

/* Desktop view */
@media (min-width: 960px) {
    .landing-footer .footer-grid {
        grid-template-columns: repeat(12, 1fr);
    }

    .landing-footer .footer-grid-item-md-4 {
        grid-column: span 5;
    }

    .landing-footer .footer-grid-item-remaining {
        grid-column: span 3;
    }
}
