body {
	margin: 0;
	font-family: 'Sk-Modernist';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.MuiTypography-root {
	font-family: 'Sk-Modernist' !important;
}

@font-face {
	font-family: 'Sk-Modernist';
	src: url('./fonts/Sk-Modernist/Sk-Modernist-Bold.otf') format('opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Sk-Modernist';
	src: url('./fonts/Sk-Modernist/Sk-Modernist-Regular.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Sk-Modernist';
	src: url('./fonts/Sk-Modernist/Sk-Modernist-light.ttf') format('truetype');
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'Gotham';
	src: url('./fonts/Gotham/Gotham-Book.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('./fonts/Gotham/Gotham-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('./fonts/Gotham/Gotham-Bold.otf') format('opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Manrope';
	src: url('./fonts/Manrope/Manrope-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.font-skmodernist {
	font-family: 'Sk-Modernist' !important;
}

.font-gotham {
	font-family: 'Gotham' !important;
}

.font-manrope {
	font-family: 'Manrope' !important;
}

.MuiInputBase-input {
	padding: 14px !important;
}

.MuiInputBase-input,
.MuiInputBase-input::placeholder {
	font-family: 'Gotham' !important;
	color: #727272 !important;
	font-size: 14px !important;
	opacity: 100% !important;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input .MuiSelect-select {
	font-family: 'Gotham' !important;
	color: #727272 !important;
	font-size: 14px !important;
	padding: 14px !important;
}

textarea {
	border-radius: 6px !important;
	border: 1px solid #d4d4d4 !important;
	padding: 14px !important;
	font-family: 'Gotham' !important;
	color: #727272 !important;
	font-size: 14px !important;
}

.MuiSelect-icon {
	color: rgba(0, 0, 0, 1) !important;
}

.text-black {
	color: #000 !important;
}

.text-light-white {
	color: #dee1e6 !important;
}

.text-lightgray {
	color: #939393 !important;
}

.text-offwhite {
	color: #f2f2f2 !important;
}

.text-grey {
	color: #727272 !important;
}

.text-gray {
	color: #757575 !important;
}

.text-gray2 {
	color: #828282 !important;
}

.text-darkgrey {
	color: #393939 !important;
}

.text-ashgrey {
	color: #4d4d4d;
}

.text-lightgrey {
	color: #acacac !important;
}

.text-slategrey {
	color: #656565 !important;
}

.text-primary {
	color: #416ad4 !important;
}

.text-primary-1 {
	color: #61a3f2 !important;
}

.text-primary-2 {
	color: #1843a0 !important;
}

.text-secondary {
	color: #864ffd !important;
}

.text-mandatory {
	color: #f00000 !important;
}

.text-breadcrumbs-active {
	color: #1843a0 !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-justify {
	text-align: justify !important;
	text-justify: inter-word !important;
}

.py-60 {
	padding-top: 60px !important;
	padding-bottom: 60px !important;
}

.my-60 {
	margin-top: 60px !important;
	margin-bottom: 60px !important;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
	font-size: 14px;
	color: #393939;
	font-family: 'Gotham' !important;
}

.main-divider {
	margin-right: -16px !important;
	margin-left: -16px !important;
	border: 1px solid #dedede;
}

.divider {
	border: 1px solid #dedede;
}

/* Accordion section starts here */

.custom-accordion {
	margin: 15px -16px !important;
	border-top: 2px solid #dedede;
}

.custom-accordion .MuiAccordion-root {
	box-shadow:
		inset 2px 0px 1px -1px #fff,
		0px 1px 1px 0px #dedede,
		0px 1px 3px 0px #fff !important;
}

.custom-accordion .MuiAccordion-root:last-of-type,
.custom-accordion .MuiAccordion-root:first-of-type {
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.custom-accordion .MuiAccordion-root.Mui-expanded {
	margin: 0px !important;
}

.custom-accordion .MuiAccordionSummary-content .MuiListItemText-primary {
	color: var(--Gray, #393939) !important;
	font-family: 'Sk-Modernist' !important;
	font-size: 15px;
}

.custom-accordion .MuiAccordionSummary-content.Mui-expanded {
	/* margin: 12px 0 0 0; */
	margin: 0px;
}

.MuiAccordion-root.Mui-expanded:last-of-type {
	border-top: 1px solid #dedede !important;
}

.custom-accordion .MuiAccordionDetails-root {
	color: #757575;
	font-family: 'Gotham' !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	border-bottom: 1px solid #dedede;
	padding: 0px 26px 16px 36px;
	text-align: start;
}

.custom-accordion .MuiListItemIcon-root {
	min-width: 20px !important;
}

.custom-accordion .MuiListItemText-root {
	margin-top: 1px;
	color: #393939;
	font-family: 'Sk-Modernist' !important;
	font-size: 15px;
}

/* Accordion section ends here */

/* Radio section starts here */

.MuiRadio-root {
	color: #393939 !important;
}

.MuiRadio-root.Mui-checked {
	color: #416ad4 !important;
}

/* Radio section ends here */

.add-button {
	border: 2px dashed #61a3f2 !important;
	padding: 14px 20px !important;
}

.social-add-button {
	width: 49px !important;
	height: 49px !important;
	margin-left: 10px !important;
	border: 1px solid #61a3f2 !important;
	border-radius: 6px !important;
}

/* Date field */
.MuiStack-root .MuiTextField-root {
	width: 100% !important;
}

/* Date field ends here */

/* Catalog section starts here */

.upload-image-container {
	border: 2px dashed #61a3f2 !important;
	border-radius: 6px !important;
	min-height: 100px;
	padding: 10px;
	display: flex;
	/* grid: 80px / auto auto auto auto; */
}

.image-inner-field {
	width: 78px !important;
	height: 80px !important;
	border-radius: 6px;
	margin-right: auto;
	position: relative;
}

.product-image {
	width: 78px;
	height: 80px;
	border-radius: 6px;
	background: #d9d9d9;
}

.product-close-icon {
	position: absolute;
	top: -5px;
	right: -5px;
}

.product-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* Adjust the minimum width as needed */
	gap: 20px;
	/* Equal gap between images */
}

.product-grid .image-inner-field {
	width: 100px !important;
	height: 100px !important;
	position: relative;
	border-radius: 6px;
}

.product-grid .product-close-icon {
	position: absolute;
	top: -5px;
	right: -5px;
}

.product-grid .product-image {
	width: 100px;
	/* Ensure the image takes up the entire width of the container */
	height: 100px;
	/* Maintain the aspect ratio */
}

.catalog-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.catalog-content {
	background-image: url('assets/catalog-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 25px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

.add-product-btn {
	border-radius: 6px !important;
	background: #fff !important;
	color: #393939 !important;
	font-size: 13px !important;
	text-transform: none !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
}

.list-item {
	margin-bottom: 16px;
	padding: 0px !important;
}

.list-item .card {
	display: flex;
	flex-direction: row !important;
	width: 100% !important;
	padding: 12px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	border: 1px solid #e3e3e3 !important;
}

.list-item .media {
	width: 120px;
	height: 110px;
	border-radius: 8px;
}

.list-item .content {
	flex: 1;
	padding: 0px 0px 0px 10px !important;
}

.list-item .actions {
	display: flex;
	gap: 8px;
	margin-top: 8px;
}

.list-item .actions .cat-edit-icon {
	padding: 0px !important;
}

.list-item .actions .cat-delete-icon {
	padding: 0px !important;
}

/* Catalog section ends here */

/* Homepage section starts here */

.MuiToolbar-root {
	padding: 0px !important;
	/* App bar */
}

.MuiAppBar-root {
	background: transparent !important;
	box-shadow: none !important;
}

.MuiAutocomplete-root {
	background: white !important;
	border-radius: 6px !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
	padding: 0px !important;
}

.MuiAutocomplete-input {
	/* padding-left: 0px !important; */
}

.homepage-background {
	background-image: url('assets/heroaria_bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: auto;
	margin: -16px;
	padding: 25px 16px;
}

.homepage-web-background {
	background-image: url('assets/heroaria_bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: auto;
	padding: 25px;
}

.homepage-web-background .MuiChip-root {
	border-radius: 4px;
	background: #f5f1fe;
	color: #864ffd;
	font-family: 'Gotham' !important;
	font-size: 13px;
}

.homepage-background .MuiChip-root {
	border-radius: 4px;
	background: #f5f1fe;
	color: #864ffd;
	font-family: 'Gotham' !important;
	font-size: 13px;
}

.verified-freelancers.MuiGrid-root {
	width: 100% !important;
	margin-left: 0px !important;
	background: #f5f4fa !important;
}

.login-btn {
	border-radius: 6px !important;
	color: #864ffd !important;
	background: #fff !important;
	font-size: 13px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	height: 35px;
	padding: 10px 10px !important;
}

.elynker-services-slider {
	display: flex;
	max-width: auto;
	overflow: scroll;
	scroll-behavior: smooth;
	transition: scroll 0.3s ease-in-out;
}

.elynker-web-services-slider {
	display: flex;
	justify-content: space-around;
	width: 90%;
	margin: 0 auto;
	overflow-x: scroll;
	scroll-behavior: smooth;
	transition: scroll 0.3s ease-in-out;
}

.elynker-services-slider .images-container,
.elynker-web-services-slider .images-container {
	position: relative;
}

.elynker-services-slider .image-text,
.elynker-web-services-slider .image-text {
	position: absolute;
	bottom: 16px;
	left: 18px;
	font-size: 15px;
	font-family: 'Sk-Modernist' !important;
	font-weight: 700;
	color: #fff;
}

.elynker-services-slider .image,
.elynker-web-services-slider .image {
	width: 175px;
	height: 210px;
	margin: 5px;
	border-radius: 8px;
}

/* Customize scrollbar */
.elynker-services-slider::-webkit-scrollbar {
	width: 8px;
	/* Width of the scrollbar */
	height: 0px;
	/* height: 5px; */
}

.elynker-services-slider::-webkit-scrollbar-track {
	background: #f1f1f1;
	/* Track color */
}

.elynker-services-slider::-webkit-scrollbar-thumb {
	background: #888;
	/* background: #864FFD; */
	border-radius: 4px;
	/* Rounded corners */
}

.elynker-services-slider::-webkit-scrollbar-thumb:hover {
	background: #555;
	/* Thumb color on hover */
}

.looking-for-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.looking-for-content {
	background-image: url('assets/looking-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 0px !important;
	color: white;
	text-align: start;
}

.looking-for-web-card {
	margin: auto;
	margin-top: 20px;
	box-shadow: none !important;
	width: 100%;
	height: 100%;
}

.looking-for-web-content {
	background-image: url('assets/why-elynker-banner.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 0px !important;
	color: white;
	text-align: start;
	width: 50%;
	height: auto;
}

.looking-for-web-service {
	background-image: url('assets/looking-web-banner.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 0px !important;
	color: white;
	text-align: start;
	width: 50%;
	height: auto;
}

.MuiBox-root.inner-content {
	justify-content: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 50px;
}

.post-requirement-btn {
	border-radius: 6px !important;
	background: #1843a0 !important;
	color: #fff !important;
	font-size: 13px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
}

.business-tools-slider {
	display: flex;
	max-width: 500px;
	overflow: scroll;
	scroll-behavior: smooth;
	transition: scroll 0.3s ease-in-out;
}

.business-tools-web-slider {
	display: flex;
	justify-content: space-between;
	width: 90%;
	margin: 0 auto;
	scroll-behavior: smooth;
	transition: scroll 0.3s ease-in-out;
}

.business-tools-slider .images-container,
.business-tools-web-slider .images-container {
	position: relative;
}

.business-tools-slider .image-text,
.business-tools-web-slider .image-text {
	font-size: 15px;
	font-family: 'Sk-Modernist' !important;
	font-weight: 400;
	color: #393939;
	transform: translateX(5px);
}

.business-tools-slider .image,
.business-tools-web-slider .image {
	width: 175px;
	height: 170px;
	margin: 5px;
	border-radius: 8px;
}

.business-tools-slider::-webkit-scrollbar {
	width: 8px;
	/* Width of the scrollbar */
	height: 5px;
}

.business-tools-slider::-webkit-scrollbar-track {
	background: #f1f1f1;
	/* Track color */
}

.business-tools-slider::-webkit-scrollbar-thumb {
	background: #888;
	/* background: #864FFD; */
	border-radius: 4px;
	/* Rounded corners */
}

.business-tools-slider::-webkit-scrollbar-thumb:hover {
	background: #555;
	/* Thumb color on hover */
}

.elynker-for-list .MuiListItem-root {
	padding: 1px 0px !important;
}

.elynker-for-list .MuiListItemIcon-root {
	min-width: 25px !important;
	margin-top: 3px;
}

.elynker-for-list .list-text .MuiListItemText-primary {
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #393939 !important;
}

.carousel-field {
	border-radius: 8px;
	background-image: url('assets/heroaria_bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	/* border: 1px solid #dedede; */
	border: none;
	padding: 20px !important;
	min-height: 200px;
	/* background-color: #864FFD; */
}

.carousel-web-field {
	min-height: 250px;
	text-align: center;
	width: 650px;
	margin: 0 auto;
}

.carousel-container {
	margin-left: 1px;
	margin-right: 3px;
}

.carousel-container .carousel-slider {
	display: flex;
	flex-direction: column-reverse;
}

.carousel.carousel-slider .control-arrow {
	opacity: 0 !important;
}

.carousel-container .carousel .control-dots {
	position: relative;
	margin: 0;
}

.carousel-container .carousel .control-dots .dot.selected,
.carousel-container .carousel .control-dots .dot:hover {
	opacity: 1;
	width: 16px;
	border-radius: 6px;
	filter: alpha(opacity=100);
}

.carousel-container .carousel .control-dots .dot {
	transition: opacity 0.25s ease-in;
	opacity: 0.3;
	filter: alpha(opacity=30);
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
	background: #864ffd;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	cursor: pointer;
	display: inline-block;
	margin: 0 8px;
}

.carousel-status {
	display: none;
}

.carousel .thumbs-wrapper {
	display: none;
}

.business-service-card {
	max-width: 100%;
	margin: 0;
	margin-bottom: 20px;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
	/* transform: translateY(70px); */
}

.business-service-content {
	background-image: url('assets/business-service-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border-top-left-radius: 14px;
	border-bottom-left-radius: 14px;
	padding: 16px !important;
	color: white;
	/* display: flex; */
	width: 70%;
	align-items: center;
	justify-content: space-between;
}

.business-service-web-card {
	max-width: 100%;
	margin: 0;
	margin-bottom: 20px;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
	/* transform: translateY(70px); */
}

.business-service-web-content {
	background-image: url('assets/business-service-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border-top-left-radius: 14px;
	border-bottom-left-radius: 14px;
	padding: 40px 20px 20px 40px !important;
	color: white;
	/* display: flex; */
	width: 70%;
	align-items: center;
	justify-content: space-between;
}

.business-service-btn {
	border-radius: 6px !important;
	color: #864ffd !important;
	background: #fff !important;
	font-size: 13px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	height: 40px;
	padding: 10px 10px !important;
}

.homepage-footer {
	background-color: #1d1d1d;
	height: auto;
	padding: 60px 35px 0 35px;
	margin: 0 -16px;
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
}

.footer-divider {
	margin-right: -34px !important;
	margin-left: -34px !important;
	height: 1px;
	background: #3b3b3b;
}

.homepage-footer-content {
	justify-content: space-between;
}

.complete-profile-card {
	max-width: 100%;
	margin: 0;
	margin-bottom: 20px;
	border-radius: 8px !important;
	border: 2px solid #e3e3e3 !important;
	box-shadow: none !important;
}

.complete-profile-content {
	background: white;
	padding: 20px 15px !important;
	color: black;
	/* Text color on top of the background image */
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.complete-profile-btn {
	border-radius: 6px !important;
	background: #864ffd !important;
	color: #fff !important;
	font-size: 13px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	padding: 10px 16px !important;
}

.progress-percentage {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.insights-card {
	max-width: 100%;
	margin: 0;
	margin-bottom: 20px;
	border-radius: 8px !important;
	border: 2px solid #e3e3e3 !important;
	box-shadow: none !important;
}

.insights-content {
	padding: 20px 15px !important;
}

.profile-review-card {
	max-width: 100%;
	margin: 0;
	margin-bottom: 20px;
	border-radius: 8px !important;
	border: 2px solid #e3e3e3 !important;
	box-shadow: none !important;
	background: #864ffd !important;
}

.profile-review-content {
	padding: 10px !important;
}

.profile-review-content .ok-btn {
	border-radius: 6px !important;
	background: rgba(255, 255, 255, 0.24) !important;
	color: #fff !important;
	font-size: 16px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	padding: 8px 10px !important;
	height: 38px;
}

.text-gain {
	color: #00b257 !important;
}

.text-connects {
	color: #a7a7a7 !important;
}

.credit-services-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.credit-services-content {
	background-image: url('assets/credit-services-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 20px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

.learn-more-btn {
	border-radius: 6px !important;
	background: #fff !important;
	color: #864ffd !important;
	font-size: 13px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
}

.digital-marketing-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.digital-marketing-content {
	background-image: url('assets/digital-marketing-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 20px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

/* Homepage section ends here */

/* Profile Details starts here */
.singleValue {
	font-family: 'Gotham' !important;
	color: #727272 !important;
	font-size: 14px !important;
}

/* Profile details ends here */

/* B2B Business section starts here */

.b2b-business .MuiChip-root {
	border-radius: 4px !important;
	background: #f0f0f0 !important;
	color: #1843a0;
	font-family: 'Gotham' !important;
	font-size: 13px;
}

.b2b-business-slider::-webkit-scrollbar {
	width: 8px;
	/* Width of the scrollbar */
	height: 5px;
}

.business-testimonial-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.business-testimonial-content {
	background-image: url('assets/testimonial-bg.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 30px 0px 25px 25px !important;
	color: white;
	height: 172px;
}

.business-testimonial-web-card {
	width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.business-testimonial-web-content {
	background-image: url('assets/testimonial-web-bg.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 80px 0px 25px 50px !important;
	color: white;
	height: 230px;
}

/* B2B Business section end here */

/* B2B Category Section starts here */
.category-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.category-content {
	background-image: url('assets/B2B-category-bg.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 40px 0px 25px 20px !important;
	color: white;
	height: 172px;
}

.category-web-content {
	background-image: url('assets/b2b-category-web-bg.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 93px 0px 25px 60px !important;
	color: white;
	height: 240px;
}

.category-web-card {
	width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.b2b-list-item {
	margin-bottom: 16px;
	padding: 0px !important;
}

.b2b-list-item .card {
	/* display: flex; */
	/* flex-direction: row !important; */
	width: 100% !important;
	padding: 14px 14px 16px 14px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	border: 1px solid #e3e3e3 !important;
}

.b2b-list-item .content {
	width: 100%;
	margin-left: 15px;
}

.b2b-list-item .category-media {
	width: 93px;
	height: 89px;
	border-radius: 8px;
}

.b2b-filter-section .MuiChip-root  {
	border-radius: 4px !important;
	background: #fff !important;
	color: #1843a0 !important;
	font-family: 'Gotham' !important;
	font-size: 13px !important;
	border: 1px solid #1843a0 ;
	height: 36px !important;
}

.b2b-filter-saved-section.b2b-filter-section .MuiChip-root.MuiChip-saved-root {
	border-radius: 4px !important;
	background-color: #1843a0 !important;
	color: #ffffff !important;
	font-family: 'Gotham' !important;
	font-size: 13px;
	border: 1px solid #fff !important;
	height: 36px;
}

.b2b-filter-section .MuiChip-root .MuiChip-icon {
	margin-left: 9px;
}

.b2b-filter-section .MuiChip-root.selected-city {
	border-radius: 4px !important;
	background: #1843a0 !important;
	color: #fff;
	font-family: 'Gotham' !important;
	font-size: 13px;
	height: 36px;
}

.b2b-filter-section .dropdown-icon {
	color: #1843a0 !important;
}

.b2b-filter-slider::-webkit-scrollbar {
	width: 8px;
	/* Width of the scrollbar */
	height: 5px;
}

.b2b-filter-slider::-webkit-scrollbar-track {
	background: #f1f1f1;
	/* Track color */
}

.b2b-filter-slider::-webkit-scrollbar-thumb {
	background: #888;
	/* background: #864FFD; */
	border-radius: 4px;
	/* Rounded corners */
}

.b2b-filter-slider::-webkit-scrollbar-thumb:hover {
	background: #555;
	/* Thumb color on hover */
}

.call-now-btn {
	border-radius: 6px !important;
	background: #fff !important;
	color: #864ffd !important;
	border: 1px solid #864ffd !important;
	width: 47% !important;
	padding: 9px 16px !important;
	letter-spacing: 1px !important;
	text-transform: capitalize !important;
	font-size: 13px !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
}

.whatsapp-btn {
	border-radius: 6px !important;
	background: #864ffd !important;
	color: #fff !important;
	font-size: 13px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	width: 47% !important;
	padding: 9px 16px !important;
	letter-spacing: 1px !important;
}

.filter-tabs .MuiTabs-indicator {
	background: #864ffd !important;
}

/* .filter-tabs .Mui-selected {
    color: #864FFD !important;
    font-weight: 400 !important;
} */

.filter-tabpanel {
	width: 70% !important;
}

.filter-tabpanel .MuiBox-root {
	padding: 0px !important;
}

.filter-tabpanel-list {
	padding-left: 20px !important;
	height: 220px;
	overflow-y: scroll;
}

.filter-tabpanel-list > .MuiListItem-root {
	border-bottom: 1px solid #eaeaea;
}

.filter-tabpanel-list .MuiListItemButton-root {
	padding-right: 0px !important;
}

.filter-tabpanel-list .MuiTypography-root {
	font-size: 14px !important;
	color: #727272 !important;
	font-family: 'Sk-Modernist' !important;
}

/* B2B Category Section ends here */

/* B2B WebCategory Section Starts Here */
.filter-web-list {
	padding-left: 0px !important;
}

.filter-web-list .MuiListItemButton-root {
	padding: 0px !important;
}

.filter-web-list .MuiListItemIcon-root {
	min-width: 0px !important;
}

.filter-web-list .MuiTypography-root,
.filter-web-sort-list .MuiTypography-root {
	font-size: 15px !important;
	color: #727272 !important;
	font-family: 'Gotham' !important;
}

.filter-web-sort-list {
	padding-left: 0px !important;
}

.filter-web-sort-list .MuiListItemIcon-root {
	min-width: 35px !important;
}

.filter-web-sort-list .MuiButtonBase-root.MuiRadio-root {
	padding: 0px !important;
}

.MuiRadio-root {
	color: #d4d4d4 !important;
}

.modal-footer.MuiDialogActions-root {
	padding: 10px 24px 20px 24px !important;
}

.cursor-pointer {
	cursor: pointer;
}
/* B2B WebCategory Section Ends Here */

@media only screen and (max-width: 600px) {
	.b2b-filter-slider::-webkit-scrollbar {
		width: 8px;
		/* Width of the scrollbar */
		height: 0px;
	}

	.b2b-filter-slider::-webkit-scrollbar-track {
		background: #f1f1f1;
		/* Track color */
	}

	.b2b-filter-slider::-webkit-scrollbar-thumb {
		background: transparent;
		/* background: #864FFD; */
		border-radius: 4px;
		/* Rounded corners */
	}

	.b2b-filter-slider::-webkit-scrollbar-thumb:hover {
		background: #555;
		/* Thumb color on hover */
	}
}

/* Profile Section Starts Here */

.profile-main-card {
	max-width: 100%;
	/* margin-top: 70px; */
	margin-top: 140px;
	margin-bottom: 20px;
	border-radius: 8px !important;
	border: 2px solid #e3e3e3 !important;
	box-shadow: none !important;
	align-items: center;
	text-align: center;
	overflow: inherit !important;
}

.profile-main-content {
	padding: 20px 15px !important;
	position: relative;
}

/* .profile-photo-main {
	position: absolute;
	right: 34%;
	top: -57px;
} */

.profile-photo-main {
	position: absolute;
	top: -57px;
	width: 100%;
	left: 0px;
}

.profile-photo-inner {
	width: 120px;
	height: 120px;
	margin: 0 auto;
	position: relative;
}

.profile-edit-icon {
	position: absolute;
	bottom: 0;
	right: 0;
}

.m-top {
	margin-top: 60px !important;
}

.MuiGrid-root.profile-stats-card {
	width: 100%;
	margin: 0;
}

.stats-number {
	transform: translateY(-16px) !important;
}

.stats-text {
	transform: translateY(-10px) !important;
}

.my-catalog-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.my-catalog-content {
	background-image: url('assets/catalog-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 25px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

.profile-card-btn {
	border-radius: 6px !important;
	background: #fff !important;
	color: #393939 !important;
	font-size: 13px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
}

.free-tier-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.free-tier-content {
	background-image: url('assets/free-tier-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 25px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

.my-requirements-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.my-requirements-content {
	background-image: url('assets/my-requirements-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 25px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

.profile-complete-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.profile-complete-content {
	background-image: url('assets/complete-profile-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 25px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

.logout-btn {
	border-radius: 6px !important;
	border: 1.5px solid #f04d23 !important;
	background: #fff !important;
	color: #f04d23 !important;
	font-size: 14px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	padding: 10px 0px 11px !important;
}

.profile-background {
	background-image: url('assets/profile_cover_bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 370px;
	margin: -17px;
	padding: 25px 16px;
}

.profile-background-2 {
	/* background-image: url('assets/profile_cover_bg.jpg'); */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 370px;
	margin: -17px;
	padding: 25px 16px;
}

.edit-cover-btn {
	color: #fff !important;
	background: none !important;
	font-size: 15px !important;
	text-transform: capitalize !important;
	font-weight: 400 !important;
	font-family: 'Sk-Modernist' !important;
	height: 35px;
	padding: 0 !important;
	box-shadow: none !important;
}

.e-business-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.e-business-content {
	background-image: url('assets/e-business-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 15px 15px !important;
	color: white;
	/* Text color on top of the background image */
}

.e-business-content .rating-icon,
.e-business-content .delivery-icon {
	margin-top: 2px;
	margin-right: 7px;
}

.e-business-card .divider {
	background: rgba(255, 255, 255, 0.7);
}

/* Profile Section Ends Here */

/* Profile Freelancer Section Starts Here */

/* Profile Freelancer Section Ends Here */
.pro-details-accordion {
	margin: 0px !important;
	border-top: none !important;
}

.pro-details-accordion .MuiAccordionSummary-root {
	border-bottom: none;
	padding: 0;
}

.pro-details-accordion
	.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
	min-height: 50px;
}

.pro-details-accordion .MuiAccordionDetails-root {
	color: #757575;
	font-family: 'Gotham' !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	border-bottom: none;
	padding: 6px 0px !important;
	text-align: start;
}

.pro-details-accordion .MuiAccordion-root {
	box-shadow: none !important;
}

.pro-details-accordion .MuiAccordion-root.Mui-expanded:last-of-type {
	border-top: none !important;
}

/* Freelancer Bio Section Starts Here */
.product-accordion {
	margin: 15px 0px !important;
	border-top: none !important;
}

.product-accordion .MuiAccordionSummary-root {
	border-bottom: 1px solid #dedede;
	padding: 0;
}

.product-accordion .MuiAccordionDetails-root {
	color: #757575;
	font-family: 'Gotham' !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	border-bottom: none;
	padding: 6px 0px !important;
	text-align: start;
}

.product-accordion .MuiAccordion-root {
	box-shadow: none !important;
}

.product-accordion .MuiAccordion-root.Mui-expanded:last-of-type {
	border-top: none !important;
}

/* Freelancer Bio Section Ends Here */

/* FL Freelance section starts here */

.b2b-business .MuiChip-root {
	border-radius: 4px !important;
	background: #f0f0f0 !important;
	color: #1843a0;
	font-family: 'Gotham' !important;
	font-size: 13px;
}

.freelance-testimonial-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.freelance-testimonial-content {
	background-image: url('assets/freelancer-testimonial-bg.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 30px 0px 25px 25px !important;
	color: white;
	height: 172px;
}

/* FL Freelance section end here */

/* FL Freelance Category Section Starts Here */
.freelance-category-card {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 40px 0px 25px 20px !important;
	color: white;
	height: 172px;
}

.freelance-category-content {
	background-image: url('assets/freelancer-category-bg.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 40px 0px 25px 20px !important;
	color: white;
	height: 172px;
}

.freelance-list-item {
	margin-bottom: 16px;
	padding: 0px !important;
}

.freelance-list-item .card {
	/* display: flex; */
	/* flex-direction: row !important; */
	width: 100% !important;
	padding: 14px 14px 16px 14px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	border: 1px solid #e3e3e3 !important;
}

.freelance-list-item .content {
	width: 69%;
	margin-left: 15px;
}

.freelance-list-item .category-media {
	width: 84px;
	height: 84px;
	border-radius: 8px;
}

/* FL Freelance Category Section Ends Here */

/* B2B Detail Section Starts Here */

.b2b-detail-card {
	margin-bottom: 16px;
	padding: 0px !important;
}

.b2b-detail-card .card {
	/* display: flex; */
	/* flex-direction: row !important; */
	width: 100% !important;
	padding: 0px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	border: none !important;
}

.b2b-detail-card .content {
	width: 100%;
	margin-left: 15px;
}

.b2b-detail-card.category-media {
	width: 82px;
	height: 79px;
	border-radius: 8px;
}

.b2b-detail-card .MuiChip-root {
	border-radius: 4px !important;
	background: #00b257 !important;
	color: #ffffff !important;
	font-family: 'Gotham' !important;
	font-weight: 400 !important;
	font-size: 13px;
}

.top-products .MuiChip-root {
	border-radius: 4px !important;
	background: #61a3f2 !important;
	color: #ffffff !important;
	font-family: 'Gotham' !important;
	font-weight: 400 !important;
	font-size: 13px;
	padding: 18px 10px !important;
}

.top-products .MuiStack-root {
	flex-wrap: nowrap;
	overflow-x: scroll;
}

.b2b-detail-tabs .Mui-selected {
	color: #864ffd !important;
}

.b2b-detail-tabs .MuiTabs-indicator {
	background-color: #864ffd !important;
}

.b2b-detail-tabs .MuiButtonBase-root {
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between !important;
}

.b2b-detail-tabs .b2b-detail-tabs-title {
	margin-right: 22px !important;
}

.b2b-detail-tabs .MuiBadge-badge {
	background-color: #eb5757 !important;
	font-size: 13px;
}

.rating-review-card {
	margin-bottom: 16px;
	margin-top: 16px;
	padding: 0px !important;
}

.rating-review-card .card {
	/* display: flex; */
	/* flex-direction: row !important; */
	width: 100% !important;
	padding: 20px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	background-color: #f6f6f6 !important;
	border: 1px solid #e3e3e3 !important;
}

.submit-rating-btn {
	border-radius: 6px !important;
	background-color: #864ffd !important;
	color: #fff !important;
	font-size: 15px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	height: 44px;
	padding: 12px 15px !important;
}

.send-req-btn {
	border-radius: 6px !important;
	border: 1px solid #e3e3e3 !important;
	background: #fff !important;
	color: #000000 !important;
	font-size: 15px !important;
	text-transform: none !important;
	font-weight: 400 !important;
	font-family: 'Sk-Modernist' !important;
	box-shadow: none !important;
}

/* B2B Detail Section Ends Here */

/* Freelancer Detail Section Ends Here  */
.freelancer-detail-card {
	margin-bottom: 16px;
	margin-top: 14px;
	padding: 0px !important;
}

.freelancer-detail-card .card {
	/* display: flex; */
	/* flex-direction: row !important; */
	width: 100% !important;
	padding: 0px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	border: none !important;
}

.freelancer-detail-card .content {
	width: 100%;
	margin-left: 15px;
}

.freelancer-detail-card .category-media {
	width: 55px;
	height: 55px;
	border-radius: 50% !important;
}

.seller-info {
	width: 100%;
}

.seller-info-title {
	width: 45%;
}

.seller-info-detail {
	width: 55%;
}

.freelancer-detail-accordion {
	margin: 15px -16px !important;
	border-top: 2px solid #dedede;
}

.freelancer-detail-accordion .MuiAccordion-root {
	box-shadow:
		inset 2px 0px 1px -1px #fff,
		0px 1px 1px 0px #dedede,
		0px 1px 3px 0px #fff !important;
}

.freelancer-detail-accordion .MuiAccordion-root:last-of-type,
.freelancer-detail-accordion .MuiAccordion-root:first-of-type {
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.freelancer-detail-accordion .MuiAccordion-root.Mui-expanded {
	margin: 0px !important;
}

.freelancer-detail-accordion
	.MuiAccordionSummary-content
	.MuiListItemText-primary {
	color: #416ad4 !important;
	font-family: 'Gotham' !important;
	font-size: 15px;
	margin-bottom: 0px !important;
	margin-top: 5px;
}

.freelancer-detail-accordion .MuiAccordionSummary-content.Mui-expanded {
	/* margin: 12px 0 0 0; */
	margin: 0px;
}

.freelancer-detail-accordion .MuiAccordionSummary-root.Mui-expanded {
	min-height: 45px;
}

.MuiAccordion-root.Mui-expanded:last-of-type {
	border-top: 1px solid #dedede !important;
}

.freelancer-detail-accordion .MuiAccordionDetails-root {
	color: #757575;
	font-family: 'Gotham' !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	border-bottom: 1px solid #dedede;
	padding: 0px 26px 16px 36px;
	text-align: start;
}

.freelancer-detail-accordion .MuiListItemIcon-root {
	min-width: 0px !important;
}

.freelancer-detail-accordion .MuiListItemText-root {
	margin-top: 1px;
	color: #393939;
	font-family: 'Sk-Modernist' !important;
	font-size: 15px;
}

.feedback-chips .MuiChip-root {
	border-radius: 4px !important;
	border: 1px solid #864ffd;
	background: #fff;
	color: #864ffd;
	font-family: 'Gotham' !important;
	font-weight: 400 !important;
	font-size: 13px;
	padding: 17px 12px !important;
}

/* .feedback-chips .MuiChip-clickable {
  background-color: red !important;
  color: #fff !important;
} */

li.MuiListItem-root.review-list {
	padding: 20px 0px;
	border-bottom: 1px solid #e3e3e3;
}

.review-list .MuiListItemAvatar-root {
	margin-top: 0px !important;
	padding-top: 5px;
}

/* Freelancer Detail Section Ends Here  */

/* B2C Section Starts Here */

.edit-profile-btn {
	color: #864ffd !important;
	background: none !important;
	font-size: 15px !important;
	text-transform: capitalize !important;
	font-weight: 400 !important;
	font-family: 'Sk-Modernist' !important;
	height: 35px;
	padding: 0 !important;
	box-shadow: none !important;
}

/* B2C Section Ends Here */

/* Subscription Sections Starts Here  */

.subscription-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.subscription-content {
	background-image: url('assets/plan-banner.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 35px 0px 25px 20px !important;
	color: white;
	height: 172px;
}

.subscription-web-card {
	width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.subscription-web-content {
	background-image: url('assets/plan-web-banner.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 120px 0px 25px 45px !important;
	color: white;
	height: 274px;
}

.basic-plan {
	margin-bottom: 16px;
	padding: 0px !important;
}

.basic-plan .card {
	/* display: flex; */
	/* flex-direction: row !important; */
	width: 100% !important;
	padding: 14px 14px 16px 14px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	border: 1px solid #61a3f2 !important;
}

.current-plan-btn {
	border-radius: 6px !important;
	background: #61a3f2 !important;
	color: #fff !important;
	font-size: 12px !important;
	text-transform: uppercase !important;
	font-weight: 400 !important;
	font-family: 'Gotham' !important;
	padding: 10px 10px 8px 10px !important;
	/* letter-spacing: 1px !important; */
}

.subscription-plan {
	margin-bottom: 16px;
	padding: 0px !important;
}

.subscription-plan .card {
	/* display: flex; */
	/* flex-direction: row !important; */
	width: 100% !important;
	padding: 22px 19px 22px 19px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	border: 1px solid #864ffd !important;
	position: relative;
}

.subscription-plan .card .star-vector-img {
	position: absolute;
	top: 0;
	right: 0;
}

.subcription-available-plans .plans-header .premium-card {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	width: 100%;
}

.subcription-available-plans .plans-header .free-card {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	width: 100%;
}

.subcription-available-plans .plans-content {
	border: 1px solid #e3e3e3 !important;
	border-radius: 6px !important;
}

.plans-content.MuiCardContent-root:last-child {
	padding-bottom: 10px !important;
}

.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.cardsContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.cardsContainer .card {
	width: 47%;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	padding: 15px 15px 16px 16px;
	position: relative;
	cursor: pointer;
	border-color: #864ffd;
}

.checkIcon {
	position: absolute;
	top: 11px;
	right: 12px;
}

.annual-discount {
	font-family: 'Gotham' !important;
	font-size: 12px !important;
	font-weight: 700 !important;
	line-height: 11.48px;
	color: #00b257 !important;
	background-color: #f0f0f0;
	border-radius: 20px;
	padding: 7px 16px 5px;
	display: inline-block;
}

.btnSubscribe {
	margin-top: 24px;
	margin-bottom: 24px;
}

/* .premium-field {
  padding-left: 0px !important;
}

.free-field {
  padding-left: 0px !important;

} */
/* Subscription Sections Ends Here */

/* Product Tools Section Starts Here  */
.product-tools-card {
	max-width: 100%;
	margin: auto;
	margin-top: 8px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.product-tools-content {
	background-image: url('assets/product-tools-bg.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 34px 0px 25px 20px !important;
	color: white;
	height: 172px;
}

.product-tools-web-card {
	width: 100%;
	margin: auto;
	margin-top: 8px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.product-tools-web-content {
	background-image: url('assets/product-tools-web-bg.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 80px 0px 25px 38px !important;
	color: white;
	height: 230px;
}

.product-tools-list-item {
	margin-bottom: 16px;
	padding: 0px !important;
}

.product-tools-list-item .card {
	/* display: flex; */
	/* flex-direction: row !important; */
	width: 100% !important;
	padding: 14px 14px 16px 14px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	border: 1px solid #e3e3e3 !important;
}

.product-tools-list-item .content {
	width: 69%;
	margin-left: 15px;
}

.product-tools-list-item .category-media {
	width: 110px;
	height: 84px;
	border-radius: 8px;
}

.view-details-btn {
	border-radius: 6px !important;
	background: #fff !important;
	color: #61a3f2 !important;
	border: 1px solid #61a3f2 !important;
	padding: 9px 16px !important;
	letter-spacing: 1px !important;
	text-transform: capitalize !important;
	font-size: 13px !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	height: 41px !important;
}

.subscribe-btn {
	border-radius: 6px !important;
	background: #864ffd !important;
	color: #fff !important;
	font-size: 13px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	padding: 9px 16px !important;
	letter-spacing: 1px !important;
	height: 41px !important;
}

.product-filter-section .MuiChip-root {
	border-radius: 4px !important;
	background: #fff;
	color: #1843a0;
	font-family: 'Gotham' !important;
	font-size: 13px;
	border: 1px solid #1843a0;
	height: 36px;
}

.product-filter-slider::-webkit-scrollbar {
	width: 8px;
	/* Width of the scrollbar */
	height: 5px;
}

/* Product Tools Section Ends Here  */

/* Elynker Services Section Starts Here */

.elynker-services-card {
	max-width: 100%;
	margin: auto;
	margin-top: 8px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.elynker-services-content {
	background-image: url('assets/elynker-services-banner.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 34px 0px 25px 20px !important;
	color: white;
	height: 172px;
}

.elynker-services-web-card {
	width: 100%;
	margin: auto;
	margin-top: 8px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.elynker-services-web-content {
	background-image: url('assets/elynker-services-web-banner.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 80px 0px 25px 50px !important;
	color: white;
	height: 230px;
}

.services-btn {
	border-radius: 6px !important;
	background: #864ffd !important;
	color: #fff !important;
	font-size: 15px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	width: 47% !important;
	padding: 11px 16px 12px 16px !important;
	letter-spacing: 1px !important;
}

/* Elynker Services Section Ends Here */

/* Active Requiremenets Section Starts Here */

.active-requirements-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.active-requirements-content {
	background-image: url('assets/active-requirements-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 25px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

.active-req-list-item {
	margin-bottom: 16px;
	padding: 0px !important;
}

.active-req-list-item .card {
	display: flex;
	flex-direction: row !important;
	width: 100% !important;
	padding: 12px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	border: 1px solid #e3e3e3 !important;
}

.active-req-list-item .media {
	width: 120px;
	height: 110px;
	border-radius: 8px !important;
}

.active-req-list-item .media-img {
	border-radius: 8px !important;
}

.active-req-list-item .content {
	flex: 1;
	padding: 0px 0px 0px 10px !important;
}

.active-req-list-item .actions {
	display: flex;
	gap: 8px;
	margin-top: 8px;
}

.active-req-list-item .actions .cat-edit-icon {
	padding: 0px !important;
}

.active-req-list-item .actions .cat-delete-icon {
	padding: 0px !important;
}

.text-hold {
	color: #4176ff !important;
}

.text-fulfilled {
	color: #00b257 !important;
}

.text-pending {
	color: #ffa700 !important;
}

.text-cancelled {
	color: #f05d23 !important;
}
.skip-btn {
	background: #fafafa !important;
	border: 1px solid #1843a0 !important;
	color: #1843a0 !important;
	font-size: 15px !important;
	font-weight: 700 !important;
	padding: 12px 20px !important;
	border-radius: 6px !important;
	text-transform: capitalize !important;
	width: 46%;
	/* max-height: 43px; */
}

.save-btn {
	background: #1843a0 !important;
	color: #fff !important;
	font-size: 15px !important;
	font-weight: 700 !important;
	padding: 8px 15px !important;
	border-radius: 6px !important;
	padding: 12px 20px !important;
	text-transform: capitalize !important;
	width: 46%;
}

/* Active Requiremenets Section Ends Here */

/* Job Application Section Starts Here */

.job-list-item {
	margin-bottom: 16px;
	padding: 0px !important;
}

.job-list-item .card {
	display: flex;
	flex-direction: row !important;
	width: 100% !important;
	padding: 16px 20px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	border: 1px solid #e3e3e3 !important;
}

.job-list-item .content {
	flex: 1;
	padding: 0px !important;
}

.job-list-item .actions {
	display: flex;
	gap: 8px;
	margin-top: 8px;
}

.job-list-item .actions .cat-edit-icon {
	padding: 0px !important;
}

.job-list-item .actions .cat-delete-icon {
	padding: 0px !important;
}
/* Job Application Section Ends Here */

/* Job Listing Section Starts Here */
.job-listing-item {
	margin-bottom: 16px;
	padding: 0px !important;
}

.job-listing-item .card {
	/* display: flex; */
	/* flex-direction: row !important; */
	width: 100% !important;
	padding: 14px 14px 16px 14px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	border: 1px solid #e3e3e3 !important;
}

.job-listing-item .content {
	width: 100%;
	margin-left: 15px;
}

.job-listing-item .category-media {
	width: 40px !important;
	height: 40px !important;
	border-radius: 50%;
}

.apply-btn {
	border-radius: 6px !important;
	background: #864ffd !important;
	color: #fff !important;
	font-size: 13px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	padding: 9px 25px !important;
	letter-spacing: 1px !important;
}

.job-listing-card {
	max-width: 100%;
	margin: auto;
	/* margin-top: 20px; */
	border-radius: 8px !important;
	box-shadow: none !important;
}

.job-listing-content {
	background-image: url('assets/job-posting-bg.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 50px 0px 25px 20px !important;
	color: white;
	height: 191px;
}
/* Job Listing Section Starts Here */

/* Product Catalog Section Starts Here */
.app-container {
	/* max-width: 600px; */
	margin: 0 auto;
	margin-top: 30px;
	padding: 0px !important;
}

.header {
	text-align: center;
	margin-bottom: 10px;
}

.divider-date {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.product-divider {
	flex-grow: 1;
	height: 12px;
	background: repeating-linear-gradient(
		113deg,
		#dde2ee,
		#dde2ee 10px,
		transparent 13px,
		transparent 15px
	);
	margin-right: 25px;
	margin-top: 4px;
}

.product-table-bordered td,
.product-table-bordered th {
	border: 1px solid #d5d0e1;
}

.product-table-bordered th {
	background-color: rgba(134, 79, 253, 0.1);
	text-align: center;
	font-size: 20px;
	font-family: 'Sk-Modernist';
	font-weight: 700;
	color: #000;
}

.product-table-bordered td {
	font-family: 'Gotham';
	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
	text-align: center;
}

.MuiListItem-root.fixture-list-item {
	padding: 0px !important;
}

.fixture-main-list {
	border: 1px solid #d5d0e1;
	border-radius: 6px;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.fixture-list-item {
	border-bottom: 1px solid #d5d0e1;
}

.fixture-card {
	display: flex;
	margin-bottom: 0px;

	border-radius: 0px;
	overflow: hidden;
	box-shadow: none !important;
	width: 100%;
	padding: 23px 19px;
}

.fixture-image {
	width: 105px;
	height: 95px;
}

.fixture-details {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0px !important;
	padding-left: 20px !important;
}

.fixtures-footer {
	margin-top: 26px !important;
	margin-bottom: 26px !important;
	text-align: center;
}

/* Product Catalog Section Ends Here */

/* Payment Details Section Starts Here */
.payment-details-table {
	box-shadow: none !important;
}

.payment-details-table .payment-details-heading {
	background: #f0f0f0;
	margin-left: -16px;
	margin-right: -16px;
	padding: 8px 24px;
}

.payment-details-table .MuiTableCell-root {
	padding: 13px 0px;
}

.payment-details-table .MuiTableBody-root .MuiTableCell-root {
	border-bottom: none !important;
}

.payment-details-table .MuiTableBody-root th.MuiTableCell-root {
	font-size: 16px;
	font-family: 'Sk-Modernist' !important;
	color: #393939;
	font-weight: 400;
}

.payment-details-table .MuiTableBody-root td.MuiTableCell-root {
	font-size: 16px;
	font-family: 'Sk-Modernist' !important;
	color: #393939;
	font-weight: 700;
}

.payment-details-table .MuiTableFooter-root th.MuiTableCell-root {
	font-size: 16px;
	font-family: 'Sk-Modernist' !important;
	color: #1843a0;
	font-weight: 400;
}

.payment-details-table .MuiTableFooter-root td.MuiTableCell-root {
	font-size: 16px;
	font-family: 'Sk-Modernist' !important;
	color: #1843a0;
	font-weight: 700;
}

.payment-details-table .MuiTableFooter-root {
	border-top: 1px solid #dedede;
	border-bottom: 1px solid #dedede;
}

.complete-payment {
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	text-align: center;
}

.btn-complete-payment {
	padding: 16px !important;
	background: #1843a0 !important;
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
	display: block !important;
	letter-spacing: 5px !important;
}
/* Payment Details Section Ends Here */

/* Web Login Sections Starts Here  */
.bgImage {
	background-image: url('assets/login-bg-admin.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
	height: 100vh;
	width: 100%;
}

.bgMain {
	background-color: #ffffff;
	/* your desired background color */
}

.logo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* Web Login Sections Ends Here  */

/* Web Onboarding Section Starts Here */

.btn-web-back {
	border-radius: 6px !important;
	border: 1px solid #d4d4d4 !important;
	background: #fff !important;
	color: #393939 !important;
	font-size: 15px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	height: 46px !important;
	padding: 15px 25px 17px 25px !important;
}

.btn-web-next {
	border-radius: 6px !important;
	background: #1843a0 !important;
	color: #fff !important;
	font-size: 15px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
	height: 46px !important;
	padding: 15px 25px 17px 25px !important;
}

/* Web Onboarding Section Ends Here */

/* Modal section starts here */

.modal-main .MuiDialog-paper {
	border-radius: 18px !important;
}

.modal-body label {
	font-size: 15px;
	color: #363636;
	font-weight: 500;
}

.modal-header {
	padding-bottom: 0px;
}

.modal-footer {
	padding: 20px 24px !important;
}

.btn-cancel {
	background: #fafafa !important;
	border: 1px solid #e4e4e4 !important;
	color: #864ffd !important;
	font-size: 13px !important;
	font-weight: 400 !important;
	padding: 8px 20px !important;
	border-radius: 6px !important;
	text-transform: capitalize !important;
	max-height: 43px;
}

.btn-save {
	background: #864ffd !important;
	color: #fff !important;
	font-size: 13px !important;
	font-weight: 500 !important;
	padding: 5px 15px !important;
	border-radius: 6px !important;
	padding: 8px 20px !important;
	text-transform: capitalize !important;
}

.custom-border-header {
	position: relative !important;
	font-size: 20px !important;
	font-weight: bold !important;
	font-family: 'Sk-Modernist' !important;
}

.MuiDialog-paper {
	border-radius: 14px !important;
}

.custom-border-header::before {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 80%;
	height: 5px;
	background: linear-gradient(
		to right,
		#1843a0 33%,
		#864ffd 33% 66%,
		#61a3f2 66% 100%
	);
	z-index: 1 !important;
}

.MuiDialogContent-root {
	padding: 20px 24px !important;
}

.MuiDialogActions-root {
	padding: 10px 24px 10px 24px !important;
}

/* Modal section ends here */

/* Terms & Conditions Section Starts Here */

.terms-list .MuiListItem-root {
	padding-left: 0px !important;
	font-size: 16px;
	font-weight: 700;
}

.terms-list .terms-heading.MuiTypography-root {
	font-weight: 700;
}

.terms-description.MuiTypography-root {
	color: #686868 !important;
	font-size: 14px;
	text-align: justify;
	font-family: 'Gotham' !important;
}

.terms-list .bullet-icon {
	min-width: 10px;
	color: #686868;
}

.terms-list .bullet-icon .MuiSvgIcon-root {
	width: 5px;
	height: 5px;
}

.terms-inner-list .MuiListItemText-root .MuiTypography-root {
	color: #686868 !important;
	font-size: 14px;
	font-family: 'Gotham' !important;
}

.privacy-list .bullet-icon {
	min-width: 15px;
	color: #000;
}

.privacy-list .bullet-icon .MuiSvgIcon-root {
	width: 7px;
	height: 7px;
}

.privacy-list .MuiListItemText-root .MuiTypography-root {
	color: #000 !important;
	font-size: 15px;
	font-family: 'Gotham' !important;
}

.privacy-list .terms-description.MuiTypography-root {
	padding-left: 15px;
}

/* Terms & Conditions Section Starts Here */

/* Job Posted Section Starts Here */

.job-posted-card {
	
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.job-posted-content {
	background-image: url('assets/job-posted-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 25px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

.job-popper-list {
    min-height: 35px !important;
}
/* Job Posted Section Starts Here */

/* Job Subscription Section Starts Here */

.job-subscription-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.job-subscription-content {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 25px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

.autocomple-location .MuiAutocomplete-input {
    padding-right: 60px !important;
}
/* Job Subscription Section Starts Here */

/* Job Requirement Section Starts Here */

.MuiChipsInputCustom .MuiInputBase-formControl {
	padding: 10px !important;
	min-height: 48px !important;
}

.MuiChipsInputCustom .MuiOutlinedInput-input {
	padding: 0px !important;
}
/* Job Requirement Section Starts Here */

/* GBP Landing Section Starts Here */
.btn-subscribe {
	border-radius: 6px !important;
	text-transform: capitalize !important;
	background-color: #1843a0 !important;
	color: #fff !important;
	padding: 11px 0px !important;
	font-size: 15px !important;
	font-family: 'Sk-Modernist' !important;
	font-weight: 700 !important;
}

.btn-request-call {
	border-radius: 6px !important;
	text-transform: capitalize !important;
	color: #1843a0 !important;
	background: #fff !important;
	border: 1px solid #1843a0 !important;
	padding: 11px 0px !important;
	font-size: 15px !important;
	font-family: 'Sk-Modernist' !important;
	font-weight: 700 !important;
}
/* GBP Landing Section Starts Here */

/* Credit VAS Landing Section Starts Here */
.loan-card {
	max-width: 180px;
	border-radius: 8px !important;
	color: white;
	background-size: cover;
	background-position: center;
	position: relative;
}

.personal-loan-card-content {
	background-image: url('assets/personal-loan-banner.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 15px 20px !important;
	color: white;
}

.invoice-crediting-card-content {
	background-image: url('assets/invoice-crediting-banner.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 15px 20px !important;
	color: white;
}

.business-loan-card-contentcard-content {
	background-image: url('assets/business-loan-banner.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 15px 20px !important;
	color: white;
}

.business-loan-card-content {
	background-image: url('assets/business-loan-banner.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 15px 20px !important;
	color: white;
}

.short-term-card-content {
	background-image: url('assets/business-loan-banner.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 15px 20px !important;
	color: white;
}

.view-more-button {
	background: none !important;
	color: #fff !important;
	font-size: 14px !important;
	padding: 0px !important;
	box-shadow: none !important;
	text-transform: capitalize !important;
	font-family: 'Sk-Modernist' !important;
}

/* Credit VAS Landing Section Ends Here */

/* Personal Loan Section Starts Here */

.verified-partners-box {
	width: 70px;
}

.verified-partners-card {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70px;
	height: 70px;
	border: 1px solid #dcdcdc !important;
	border-radius: 8px;
	box-shadow: none !important;
	margin-bottom: 8px;
}
/* Personal Loan Section Ends Here */

/* View Subscription Section Starts Here */

/* .short-logo-field {
    border: 1px solid #dedddd !important;
    border-radius: 6px !important;
    padding: 24px 22px !important;
    width: 112px;
    height: 112px;
} */
/* View Subscription Section Ends Here */

.carousalcenter.MuiTypography-root {
	text-align: center !important;
	font-size: 26px !important;
}

.homepage-mobile-footer {
	background-color: #1d1d1d;
	height: auto;
	padding: 20px 20px 0 20px;
	margin: 0 -16px;
}

.usefullink-container {
	margin-top: 4px;
	margin-bottom: 10px;
}

.link-container {
	margin: 8px;
}

.link-container a {
	margin-right: 10px;
}

.support-link {
	margin-bottom: '20px';
	display: 'block';
}
@media only screen and (min-width: 1280px) {
	.b2b-services-img {
		width: 100% !important;
		height: 250px !important;
	}
}

.credit-services-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.credit-services-content {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 20px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

.learn-more-btn {
	border-radius: 6px !important;
	background: #fff !important;
	color: #864ffd !important;
	font-size: 13px !important;
	text-transform: capitalize !important;
	font-weight: 700 !important;
	font-family: 'Sk-Modernist' !important;
}

.digital-marketing-card {
	max-width: 100%;
	margin: auto;
	margin-top: 20px;
	border-radius: 8px !important;
	box-shadow: none !important;
}

.digital-marketing-content {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 20px 20px !important;
	color: white;
	/* Text color on top of the background image */
}

/* / Chrome, Safari, Edge, Opera / */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* / Firefox / */
input[type='number'] {
	-moz-appearance: textfield;
}

@media (min-width: 1536px) {
	.MuiContainer-root {
		max-width: none !important;
	}
}
